<template>
  <Auth :mode="'reset'" />
</template>

<script>
import Auth from '@/components/Auth.vue';
export default {
  name: 'Reset',
  components: {
    Auth,
  },
};
</script>
